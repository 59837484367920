import React from "react";
import { Link as ScrollLink } from "react-scroll";

import "./Projects.scss";

import content from "../../content";

export default function Projects() {
  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center mt-5 md:mt-2 mb-2 md:mb-2 font-dosis"
      id="projects"
    >
      <h1 className="text-5xl font-bold mb-5">{content.projects.title}</h1>
      {content.projects.projects.map((project, index) => {
        return (
          <div
            className="project-card flex justify-center w-10/12 md:w-6/12"
            key={index}
          >
            <img
              className="project-bgrnd"
              src={project.bgrnd}
              alt={project.name}
            />
            <div className="project-wrapper w-10/12 text-center">
              <div className="project-content py-4 px-3 px-md-5">
                <img
                  className="project-logo mb-3"
                  src={project.logo}
                  alt={project.name}
                />
                <p className="project-title font-bold text-xl text-2xl mb-3">
                  {project.title}
                </p>
                <p className="project-description text-md md:text-xl mb-3">
                  {project.description}
                </p>
                <p className="project-description text-md md:text-xl mb-3">
                  <b>Stack</b>: {project.stack}
                </p>
              </div>
              <div className="project-btns d-flex justify-content-between justify-content-md-end text-lg md:text-xl mt-4 no-underline">
                {project.website && (
                  <a href={project.website} target="_blank" rel="noreferrer">
                    <button
                      className="btn-project btn-hover border-solid rounded-lg py-2 px-4 me-md-4"
                      style={project.cBtnWebsite}
                    >
                      WEBSITE
                    </button>
                  </a>
                )}
                <a href={project.repository} target="_blank" rel="noreferrer">
                  <button
                    className="btn-project btn-hover border-solid rounded-lg py-2 px-4"
                    style={project.cBtnRepository}
                  >
                    REPOSITORY
                  </button>
                </a>
              </div>
            </div>
          </div>
        );
      })}
      <div className="btn-projects d-flex justify-content-evenly white-primary-color mb-5">
        <ScrollLink to="about" smooth={true} duration={200} delay={10}>
          <button className="yellow-primary-background btn-hover font-bold me-4 px-4 md:px-10 py-3 text-base md:text-xl uppercase rounded-lg">
            ABOUT
          </button>
        </ScrollLink>
        <ScrollLink to="contact" smooth={true} duration={200} delay={10}>
          <button className="green-primary-background btn-hover font-bold px-4 md:px-10 py-3 text-base md:text-xl uppercase rounded-lg">
            CONTACT
          </button>
        </ScrollLink>
      </div>
    </div>
  );
}
