import React from "react";
// import { Link } from "react-scroll";
import Typical from "react-typical";

import content from "../../content";

import "./Contact.scss";

export default function Contact() {
  return (
    <div
      className="min-h-screen flex items-center justify-center green-primary-background"
      id="contact"
    >
      <div className="w-10/12 mx-auto">
        <div className="text-white font-dosis text-left typical-content">
          <h1 className="text-3xl md:text-5xl font-bold mb-0">
            {content.contact.text}
          </h1>
          <h2 className="font-bold text-4xl text-gray-500">
            <Typical
              steps={content.contact.typical}
              loop={Infinity}
              className="inline-block"
            />
          </h2>
        </div>
        <div className="d-block d-md-flex justify-content-center align-items-center">
          <img
            className="big-contact-circle animate-floatHorizontal"
            src={content.contact.img}
            alt=""
          />
          <a
            href="mailto:hello@labietelabiete.com"
            target="_blank"
            rel="noreferrer"
            className="text-white font-bold font-dosis text-3xl md:text-6xl text-decoration-none"
          >
            {content.contact.mail}
          </a>
          <img
            className="small-contact-circle mt-4 animate-float"
            src={content.contact.imgRotate}
            alt=""
          />
        </div>
        {/* <div className="w-full md:w-2/5 animate-floatSlow">
          <LazyLoadImage
            src={content.header.img}
            effect="blur"
            delayTime="6000"
            placeholderSrc={process.env.PUBLIC_URL + "/logo512.png"}
          />
        </div> */}
      </div>
    </div>
  );
}
