import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { slide as Menu } from "react-burger-menu";

import { PUBLIC } from "./../../constants/routes.js";

import {
  IoPersonCircleOutline,
  IoCodeWorking,
  IoMailOutline,
  IoSearch,
} from "react-icons/io5";
import {
  GrStackOverflow,
  GrLinkedin,
  GrInstagram,
  GrGithub,
} from "react-icons/gr";

import content from "./../../content";

import "./Navbar.scss";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState();

  useEffect(() => {
    setIsOpen(false);
  }, []);

  const clickLinkHandler = () => {
    setIsOpen(false);
  };

  const stateChangeHandler = (newState) => setIsOpen(newState.isOpen);

  return (
    <>
      <div className="blue-primary-background fixed top-0 w-screen z-40 hidden md:inline-flex">
        <div className="flex items-center justify-between w-10/12 mx-auto py-3 font-dosis">
          <div>
            <ScrollLink to="header" smooth={true} duration={200} delay={10}>
              <img
                src={content.nav.logo}
                alt="logo"
                className="h-8 cursor-pointer"
              />
            </ScrollLink>
          </div>
          <div className="flex align-items-center">
            <div>
              {content.nav.links.map((link, index) => {
                return (
                  <ScrollLink
                    className="navbar-item px-3"
                    to={link.to}
                    key={index}
                    smooth={true}
                    duration={200}
                    delay={10}
                  >
                    {link.text}
                  </ScrollLink>
                );
              })}
            </div>
            <div className="social-media-right-wraper flex justify-content-center ms-4">
              <a
                href="https://www.linkedin.com/in/hugogomtej/"
                target="_blank"
                rel="noreferrer"
              >
                <GrLinkedin />
              </a>
              <a
                href="https://github.com/labietelabiete"
                target="_blank"
                rel="noreferrer"
              >
                <GrGithub />
              </a>
              <a
                href="https://www.instagram.com/labietelabiete/"
                target="_blank"
                rel="noreferrer"
              >
                <GrInstagram />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="blue-primary-background fixed top-0 w-screen py-3 z-40 flex items-center justify-between md:hidden">
        <div>
          <ScrollLink to="header" smooth={true} duration={200} delay={10}>
            <img
              src={content.nav.logo}
              alt="logo"
              className="h-6 ps-4 cursor-pointer"
            />
          </ScrollLink>
        </div>
      </div>
      <Menu onStateChange={stateChangeHandler} right isOpen={isOpen}>
        <div className="navbar-logo mb-4">
          <Link to={PUBLIC.HOME}>
            <img src={content.nav.logo} alt="logo" className="h-8" />
          </Link>
        </div>
        <div>
          <IoPersonCircleOutline />
          <ScrollLink
            onClick={clickLinkHandler}
            to={content.nav.links[0].to}
            smooth={true}
            duration={200}
            delay={10}
          >
            {content.nav.links[0].text}
          </ScrollLink>
        </div>
        <div>
          <GrStackOverflow />
          <ScrollLink
            onClick={clickLinkHandler}
            to={content.nav.links[1].to}
            smooth={true}
            duration={200}
            delay={10}
          >
            {content.nav.links[1].text}
          </ScrollLink>
        </div>
        <div>
          <IoCodeWorking />
          <ScrollLink
            onClick={clickLinkHandler}
            to={content.nav.links[2].to}
            smooth={true}
            duration={200}
            delay={10}
          >
            {content.nav.links[2].text}
          </ScrollLink>
        </div>
        <div>
          <IoMailOutline />
          <ScrollLink
            onClick={clickLinkHandler}
            to={content.nav.links[3].to}
            smooth={true}
            duration={200}
            delay={10}
          >
            {content.nav.links[3].text}
          </ScrollLink>
        </div>

        {/* {content.nav.links.map((link, index) => {
          return (
            <div key={index}>
                      <IoMailOutline />

              <ScrollLink
                onClick={clickLinkHandler}
                to={link.to}
                smooth={true}
                duration={200}
                delay={10}
              >
                {link.text}
              </ScrollLink>
            </div>
          );
        })} */}

        <div className="search-wrapper mt-3 justify-content-between">
          <input className="search-input w-9/12 rounded" />
          <IoSearch />
        </div>
        <div className="social-media-wraper justify-content-center mt-3">
          <a
            href="https://www.linkedin.com/in/hugogomtej/"
            target="_blank"
            rel="noreferrer"
          >
            <GrLinkedin />
          </a>
          <a
            href="https://github.com/labietelabiete"
            target="_blank"
            rel="noreferrer"
          >
            <GrGithub />
          </a>
          <a
            href="https://www.instagram.com/labietelabiete/"
            target="_blank"
            rel="noreferrer"
          >
            <GrInstagram />
          </a>
        </div>
      </Menu>
    </>
  );
}
