import React from "react";
import { Link as ScrollLink } from "react-scroll";

import content from "../../content";

export default function About() {
  return (
    <div
      className="min-h-screen flex flex-column items-center justify-center grey-primary-background"
      id="about"
    >
      <h2 className="text-center blue-primary-color ease-in-out mt-5 md:mt-0 font-dosis text-5xl font-bold">
        {content.about.text.header}
      </h2>

      <div className="w-8/12 md:mx-auto mt-4 md:mt-0 flex flex-col md:flex-row items-center justify-around row">
        <div className="col col-md-5">
          <img src={content.about.img} alt="about-img" />
        </div>
        <div className="blue-primary-color mt-4 md:mt-0 px-0 ps-md-4 text-start font-dosis col text-base md:text-xl">
          <p>{content.about.text.description[0]}</p>
          <p>{content.about.text.description[1]}</p>
          <p>{content.about.text.description[2]}</p>
          <div className="d-flex justify-content-evenly white-primary-color mb-5 mb-md-2">
            <ScrollLink to="projects" smooth={true} duration={200} delay={10}>
              <button className="yellow-primary-background btn-hover font-bold me-4 px-4 md:px-10 py-3 text-base md:text-xl uppercase mt-10 rounded-lg">
                PROJECTS
              </button>
            </ScrollLink>
            <ScrollLink to="contact" smooth={true} duration={200} delay={10}>
              <button className="green-primary-background btn-hover font-bold px-4 md:px-10 py-3 text-base md:text-xl uppercase mt-10 rounded-lg">
                CONTACT
              </button>
            </ScrollLink>
          </div>
        </div>
      </div>
    </div>
  );
}
