import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link as ScrollLink } from "react-scroll";

import content from "../../content";

export default function Stack() {
  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center mt-5 md:mt-2 mb-4 md:mb-2 font-dosis"
      id="stack"
    >
      <h1 className="text-5xl font-bold mb-5">{content.stack.title}</h1>
      <div className="flex justify-center w-10/12 md:w-6/12 row">
        {content.stack.tech.map((tech, index) => {
          return (
            <div className="col-6 col-md-3 p-3 p-md-3" key={index}>
              <a href={tech.link} target="_blank" rel="noreferrer">
                <span
                  className={`${
                    index % 2 === 0 ? " animate-float" : " animate-refloat"
                  } flex justify-center items-center bg-white shadow-2xl rounded-full p-3 `}
                >
                  <LazyLoadImage src={tech.img} alt={tech.alt} />
                </span>
              </a>
            </div>
          );
        })}
      </div>
      {/* <p className="w-10/12 md:max-w-xl text-base md:text-xl text-center mt-5">
        {content.stack.desc}
      </p> */}
      <ScrollLink to="projects" smooth={true} duration={200} delay={10}>
        <button className="yellow-primary-background btn-hover white-primary-color font-bold me-4 px-4 md:px-10 py-3 text-base md:text-xl uppercase mt-10 rounded-lg">
          PROJECTS
        </button>
      </ScrollLink>
    </div>
  );
}
