// eslint-disable-next-line import/no-anonymous-default-export
export default {
  nav: {
    logo: process.env.PUBLIC_URL + "/assets/labietelabiete-sign-white.png",
    links: [
      { text: "About", to: "about", icon: "IoPersonCircleOutline" },
      { text: "Stack", to: "stack", icon: "IoPersonCircleOutline" },
      { text: "Projects", to: "projects", icon: "IoPersonCircleOutline" },
      { text: "Contact", to: "contact", icon: "IoPersonCircleOutline" },
    ],
  },
  header: {
    img: process.env.PUBLIC_URL + "/assets/logo-gradient.png",
    text: ["Hi!", "It's me, Hugo Gomez.", "I am "],
    typical: [
      "Full Stack Developer 🖥️",
      2000,
      "Robotic Engineer 🤖",
      2000,
      "GoPro Content Creator 📷",
      2000,
    ],
    btnText: "STACK",
  },
  about: {
    img: process.env.PUBLIC_URL + "/assets/about-me-various-min.png",
    text: {
      header: "About",
      description: [
        "Born and raised in Seville, currently based in Barcelona.",
        "Off the computer you can find me photographing nature, I really love outdoor sports like diving, trekking, climbing o surfing, recording the adventures with my mates and my GoPro or designing music covers and PES kits.",
        "Mixin’ between Flamenco and Techno, it’s possible to get me raving in the middle of nowhere.",
      ],
    },
  },
  stack: {
    title: "Stack",
    tech: [
      {
        img: process.env.PUBLIC_URL + "/assets/stack/mongodb-logo.png",
        link: "https://www.mongodb.com/",
        alt: "mongodb",
      },
      {
        img: process.env.PUBLIC_URL + "/assets/stack/express-logo.png",
        link: "https://expressjs.com/",
        alt: "express",
      },

      {
        img: process.env.PUBLIC_URL + "/assets/stack/react-logo.png",
        link: "https://reactjs.org/",
        alt: "react",
      },
      {
        img: process.env.PUBLIC_URL + "/assets/stack/node-logo.png",
        link: "https://nodejs.org/",
        alt: "node",
      },
      {
        img: process.env.PUBLIC_URL + "/assets/stack/redux-logo.png",
        link: "https://redux.js.org/",
        alt: "redux",
      },
      {
        img: process.env.PUBLIC_URL + "/assets/stack/php-logo.png",
        link: "https://www.php.net/",
        alt: "php",
      },
      {
        img: process.env.PUBLIC_URL + "/assets/stack/mysql-logo.png",
        link: "https://www.mysql.com/",
        alt: "mysql",
      },
      {
        img: process.env.PUBLIC_URL + "/assets/stack/sass-logo.png",
        link: "https://sass-lang.com/",
        alt: "sass",
      },
    ],
    desc: `Mostly focus on Front Development, being a React passionate, I enjoy working with MERN Stack (MongoDB Express ReactJS NodeJS) or PHP mySQL for Backend`,
  },
  projects: {
    title: "Projects",
    projects: [
      {
        name: "wave-app",
        title: "WaveApp",
        logo: process.env.PUBLIC_URL + "/assets/projects/wave-app/logo.png",
        bgrnd: process.env.PUBLIC_URL + "/assets/projects/wave-app/bgrnd.png",
        description:
          "Streaming music web application where you can share and discover songs, playlists and albums with other users.",
        stack: "MongoDB, Express, React, NodeJS and Laravel.",
        website: "https://the-wave-app.netlify.app/",
        repository: "https://github.com/rocket-team-webdev/wave",
        cBtnWebsite: { color: "#ffffff", backgroundColor: "#000000" },
        cBtnRepository: { color: "#ffffff", backgroundColor: "#000000" },
      },

      {
        name: "camera-ecommerce-mern",
        title: "Camera MERN",
        logo:
          process.env.PUBLIC_URL +
          "/assets/projects/camera-ecommerce-mern/logo.png",
        bgrnd:
          process.env.PUBLIC_URL +
          "/assets/projects/camera-ecommerce-mern/bgrnd.png",
        description:
          "First full stack project, using MERN stack. It is a camera ecommerce with all the purchase proccess, including registation with Firebase.",
        stack: "MongoDB, Express, React and NodeJS.",
        website: null,
        repository:
          "https://github.com/labietelabiete/node-develop-your-mvc-project",
        cBtnWebsite: null,
        cBtnRepository: { color: "#ffffff", backgroundColor: "#000000" },
      },
      {
        name: "react-recipe",
        title: "React Recipe",
        logo: process.env.PUBLIC_URL + "/assets/projects/react-recipe/logo.png",
        bgrnd:
          process.env.PUBLIC_URL + "/assets/projects/react-recipe/bgrnd.png",
        description:
          "Find for your favourite recipe searching for ingredients or steps. Frontend landing page consuming Edaman’s API recipes using glassmorphism style.",
        stack: "React, Axios and TailwindCSS.",
        website: "https://recipe-app-react-eight.vercel.app/",
        repository: "https://github.com/labietelabiete/recipe-app-react",
        cBtnWebsite: { color: "#ffffff", backgroundColor: "#FFC759" },
        cBtnRepository: { color: "#ffffff", backgroundColor: "#FFC759" },
      },
      {
        name: "php-filesystem-explorer",
        title: "PHP Filexplorer",
        logo:
          process.env.PUBLIC_URL +
          "/assets/projects/php-filesystem-explorer/logo.png",
        bgrnd:
          process.env.PUBLIC_URL +
          "/assets/projects/php-filesystem-explorer/bgrnd.png",
        description:
          "App built just by PHP, the target of this project was get started with this language, and understand the behaviour of PHP and file management system.",
        stack: "PHP.",
        website: null,
        repository: "https://github.com/labietelabiete/filesystem-explorer",
        cBtnWebsite: null,
        cBtnRepository: { color: "#ffffff", backgroundColor: "#F06543" },
      },
      {
        name: "react-todo",
        title: "React TODO",
        logo: process.env.PUBLIC_URL + "/assets/projects/react-todo/logo.png",
        bgrnd: process.env.PUBLIC_URL + "/assets/projects/react-todo/bgrnd.png",
        description:
          "First project using React, the tipical TODO list, understanding the behaviour of states, hooks or props drilling in React.",
        stack: "React and SASS.",
        website: "https://reactjs-todo-list-two.vercel.app/",
        repository: "https://github.com/labietelabiete/reactjs-todo-list",
        cBtnWebsite: { color: "#ffffff", backgroundColor: "#047C84" },
        cBtnRepository: { color: "#ffffff", backgroundColor: "#022833" },
      },
      {
        name: "blog-api",
        title: "Blog API",
        logo: process.env.PUBLIC_URL + "/assets/projects/blog-api/logo.png",
        bgrnd: process.env.PUBLIC_URL + "/assets/projects/blog-api/bgrnd.png",
        description:
          "Project for learning how to use APIs and basic CRUD operations, where it is possible to post, edit, delete posts and comments. Wild Street 90’s style.",
        stack: "HTML5, CSS3, JQuery and Axios.",
        website: "https://www.labietelabiete.com/blog-api",
        repository: "https://github.com/labietelabiete/blog-with-api",
        cBtnWebsite: { color: "#ffffff", backgroundColor: "#95C5B5" },
        cBtnRepository: { color: "#ffffff", backgroundColor: "#E23049" },
      },
      {
        name: "js-calendar",
        title: "JS Calendar",
        logo: process.env.PUBLIC_URL + "/assets/projects/js-calendar/logo.png",
        bgrnd:
          process.env.PUBLIC_URL + "/assets/projects/js-calendar/bgrnd.png",
        description:
          "JS Calendar is a quick scheduler for meetings and events and get reminders about upcoming activities. Logic based in JS Vanilla and events stored in localStorage.",
        stack: "HTML5, CSS3 and JS Vanilla.",
        website: "https://www.labietelabiete.com/js-calendar",
        repository: "https://github.com/labietelabiete/js-calendar",
        cBtnWebsite: { color: "#ffffff", backgroundColor: "#000000" },
        cBtnRepository: { color: "#000000", backgroundColor: "#FFFF00" },
      },
      {
        name: "playing-js",
        title: "Playing Javascript",
        logo: process.env.PUBLIC_URL + "/assets/projects/playing-js/logo.png",
        bgrnd: process.env.PUBLIC_URL + "/assets/projects/playing-js/bgrnd.png",
        description:
          "Playing JavasScript is a computer game based in retro-pixel style where you have to play testing your mouse-click skills, you can try two different levels.",
        stack: "HTML5, CSS3 and JS Vanilla.",
        website: "https://www.labietelabiete.com/playing-js",
        repository: "https://github.com/labietelabiete/js-play",
        cBtnWebsite: { color: "#ffffff", backgroundColor: "#97c1e6" },
        cBtnRepository: { color: "#ffffff", backgroundColor: "#ff4d80" },
      },
    ],
  },
  contact: {
    img: process.env.PUBLIC_URL + "/assets/contact-circle.png",
    imgRotate: process.env.PUBLIC_URL + "/assets/contact-circle-rotate.png",
    text: "Send me your",
    typical: ["bad jokes", 2000, "high ideas", 2000, "budding projects", 2000],
    mail: "hello@labietelabiete.com",
  },
};
