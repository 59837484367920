import React from "react";

import { GrLinkedin, GrInstagram, GrGithub } from "react-icons/gr";

export default function Footer() {
  return (
    <div className="footer-wrapper d-md-flex justify-content-between align-items-center px-1 px-md-5 py-3 blue-primary-background white-primary-color">
      <div className="social-media-wraper d-flex justify-content-center mb-3 mb-md-0">
        <a
          href="https://www.linkedin.com/in/hugogomtej/"
          target="_blank"
          rel="noreferrer"
          className="px-2 text-xl"
        >
          <GrLinkedin />
        </a>
        <a
          href="https://github.com/labietelabiete"
          target="_blank"
          rel="noreferrer"
          className="px-2 text-xl"
        >
          <GrGithub />
        </a>
        <a
          href="https://www.instagram.com/labietelabiete/"
          target="_blank"
          rel="noreferrer"
          className="px-2 text-xl"
        >
          <GrInstagram />
        </a>
      </div>
      <div className="d-flex align-items-center justify-content-center font-dosis text-md-lg">
        <p className="mb-0 px-2">Hugo Gomez</p>
        <p className="mb-0 px-2"> ©2021</p>
        <a
          href="mailto:hello@labietelabiete.com"
          target="_blank"
          rel="noreferrer"
          className="mb-0 px-2 white-primary-color"
        >
          hello@labietelabiete.com
        </a>
      </div>
    </div>
  );
}
