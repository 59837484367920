import React from "react";

import Header from "./../../components/Header";
import Navbar from "./../../components/Navbar";
import Stack from "./../../components/Stack";
import Projects from "./../../components/Projects";
import About from "./../../components/About";
import Contact from "./../../components/Contact";
import Footer from "./../../components/Footer";

export default function Home() {
  return (
    <>
      <Navbar />
      <Header />
      <About />
      <Stack />
      <Projects />
      <Contact />
      <Footer />
    </>
  );
}
